import styled, { css } from 'styled-components'
import ImgBase from 'gatsby-image'
import Button from 'components/button'
import theme from 'styles/theme'
import { heading, paragraph } from 'styles/typography'
import mq from 'styles/mq'

export const Hero = styled.section`
  margin-top: 7rem;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 4rem;
  background-color: ${theme.color.background.light};
  z-index: 0;

  ${mq.medium} {
    padding: 12rem 0;
  }
`

export const Img = styled(ImgBase)`
  display: none;
  z-index: -1;

  img {
    width: auto !important;
    object-fit: contain;
    object-position: 100% 50%;
  }

  ${mq.desktop} {
    display: block;
    position: absolute !important;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;

    img {
      left: auto !important;
      right: 0;
    }
  }
`

export const ImgTablet = styled(Img as any)`
  display: none;
  z-index: -1;

  img {
    width: auto !important;
    object-fit: contain;
    object-position: 100% 50%;
  }

  ${mq.medium} {
    display: block;
    position: absolute !important;
    top: 0;
    right: 0;
    width: 100%;
    height: 75%;

    img {
      left: auto !important;
      right: 0;
    }
  }

  ${mq.desktop} {
    display: none;
  }
`

export const ImgMobile = styled(Img as any)`
  display: block;

  ${mq.medium} {
    display: none;
  }
`

export const H1 = styled.h1`
  ${heading.xxl};
  margin: 2rem 0 -0.2em;

  ${mq.extraSmall} {
    font-size: 3rem;
  }

  ${mq.small} {
    font-size: 3.8rem;
  }

  ${mq.extraSmall} {
    font-size: 3rem;
  }

  ${mq.small} {
    font-size: 3.8rem;
  }

  ${mq.medium} {
    ${heading.xxl};
    margin-top: 0;
  }
`

export const Intro = styled.div`
  ${paragraph.small}
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${mq.medium} {
    max-width: 40em;
  }
`

export const Contents = styled.ul<{ isExpanded: boolean }>`
  margin: 0 0 0 1em;
  padding: 0;

  ${mq.medium} {
    max-width: 44rem;
  }

  ${mq.desktop} {
    max-width: none;
  }

  ${props =>
    !props.isExpanded &&
    css`
      display: none;

      ${mq.medium} {
        display: block;
      }
    `}

  li {
    margin: 0.2em 0;
    list-style: none;

    &:before {
      content: '';
      float: left;
      width: 0.4rem;
      height: 0.4rem;
      margin: 0.6em 0.5em 0 -1em;
      background: ${theme.color.accent.primary};
    }
  }
`

export const Price = styled.strong`
  ${heading.s};
  display: block;
  margin: 1.2em 0;

  ${mq.medium} {
    order: 0;
  }
`

export const OldPrice = styled.span`
  color: ${theme.color.text.lightGrey};
  text-decoration: line-through;
`

export const DiscountOffer = styled.span`
  ${heading.xs};
  color: ${theme.color.text.green};
  font-weight: bold;
  padding-bottom: 2.4rem;
`

export const Features = styled.ul`
  ${heading.xs};
  list-style: none;
  margin: 2.5rem 0 0;
  padding: 0;
  font-size: inherit;

  li {
    display: inline-block;
    margin: 1rem 2.7rem 1rem 0;
  }

  svg {
    display: inline-block;
    vertical-align: middle;
    margin-top: -0.18em;
    font-size: 2.4em;
    color: ${theme.color.accent.secondary};
  }

  ${mq.medium} {
    max-width: 30rem;
  }

  ${mq.desktop} {
    max-width: 36rem;
  }

  ${mq.large} {
    max-width: none;
  }
`

export const ReviewsAccessPoint = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
`

export const ReviewsAccessPointStars = styled.div`
  margin-right: 1rem;
`

export const ReviewsAccessPointLink = styled.div`
  border-bottom: 0.1rem solid;
`

export const MobileHideButtonWrapper = styled.div`
  display: none;

  ${mq.medium} {
    display: block;
  }
`
