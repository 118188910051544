import React from 'react'
import Container from 'components/container'
import {
  Heading,
  DesktopBirdAndBee,
  MobileBirdAndBee,
  List,
  Section,
  Subtitle,
  Wrapper,
  ContentWrapper,
  ImgWrapper,
} from './styled'

const WhyGetAnalysis = () => (
  <Section>
    <Container>
      <Heading>Why get a semen analysis?</Heading>
      <Subtitle>
        There are many reasons why you may want a semen analysis:
      </Subtitle>
      <Wrapper>
        <ContentWrapper>
          <List>
            <li>A doctor recommended it for you</li>
            <li>
              You&apos;re looking to pursue the first step in a fertility
              evaluation
            </li>
            <li>You&apos;re trying to conceive</li>
          </List>
          <List>
            <li>
              You&apos;re testing the success of a vasectomy or a vasectomy
              reversal
            </li>
            <li>
              You&apos;re curious how your fertility is impacted by your overall
              health
            </li>
          </List>
        </ContentWrapper>
        <ImgWrapper>
          <MobileBirdAndBee />
          <DesktopBirdAndBee
            // @ts-ignore
            viewbox="0 19 87 87"
          />
        </ImgWrapper>
      </Wrapper>
    </Container>
  </Section>
)

export default WhyGetAnalysis
