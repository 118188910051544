import React from 'react'
import Img, { FluidObject } from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { PrimaryLink } from 'components/links'

import {
  Content,
  ContentWrap,
  Heading,
  ImageWrap,
  Paragraph,
  Section,
} from './styled'

import { ScreenSize } from 'styles/mq'
import Urls from 'common/urls'

type QueryResult = {
  cryoCanister: {
    childImageSharp: {
      fluid: FluidObject
    }
  }
}

const query = graphql`
  query {
    cryoCanister: file(relativePath: { eq: "cryo-canister.png" }) {
      childImageSharp {
        fluid(quality: 90, webpQuality: 100, maxWidth: 124) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const SaveYourSperm = () => {
  const data = useStaticQuery<QueryResult>(query)

  return (
    <Section>
      <ContentWrap>
        <Content>
          <Heading>Save your healthiest sperm for later.</Heading>
          <Paragraph>
            Your sperm gradually degrade as you get older, and sperm quality can
            be greatly affected by your overall health and lifestyle. We offer
            convenient and affordable cryopreservation of your sperm to help
            protect your ability to conceive through fertility treatment with a
            partner for years to come. This service is not intended to be used
            for directed donor or surrogacy.<sup>¶</sup>
          </Paragraph>
          <PrimaryLink to={Urls.kitPlusCryo}>Learn More</PrimaryLink>
        </Content>
      </ContentWrap>
      <ImageWrap>
        <Img
          fluid={{
            ...data.cryoCanister.childImageSharp.fluid,
            sizes: `(min-width: ${ScreenSize.LG}px) 50vw, 100vw`,
          }}
          alt=""
        />
      </ImageWrap>
    </Section>
  )
}

export default SaveYourSperm
