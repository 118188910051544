import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const Icon: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props}>
    <path
      d="M58.408 34.38l4.454-16.88L49.39 28.524 34.8 19.05l6.266 16.288-13.474 11.025 12.86-.71c-.05.4-.1.8-.168 1.2-.314 1.916-.529 4.314-1.472 5.951a2.26 2.26 0 0 1-1.066 1.015 1.546 1.546 0 0 1-1.785-.442c-.818-.907-1.11-2.189-1.808-3.2a2.424 2.424 0 0 0-.514-.581 1.2 1.2 0 0 0-1.253-.068 5 5 0 0 0-2.055 2.916 24.367 24.367 0 0 0-1.183 4.469c-.239 1.477-.41 2.967-.7 4.434a19.783 19.783 0 0 1-.5 2.182c-.187.585-.513 1.568-1.093 1.883-.858.467-1.766-1.064-2.122-1.609a8.494 8.494 0 0 0-2.041-2.638c-2.382-1.594-5.336 7.589-4.568 8.362s2.531-4.463 3.282-5.439c1.381-1.797 3.441 6.412 5.41 6.412.959 0 1.67-1.046 2.116-1.769a16.834 16.834 0 0 0 1.573-3.514c.708-2.127.9-4.458 1.9-6.478.331-.665.894-1.351 1.707-1.249a2.948 2.948 0 0 1 1.94 1.61 20.582 20.582 0 0 0 1.572 3.18c.621.763 1.394 1.141 2.2.653 1.676-1.013 2.557-3.086 3.358-4.793a74.511 74.511 0 0 0 3.133-9.147l4.887 12.7 4.453-16.88L73 43.854z"
      fill="currentColor"
    />
  </Svg>
)

export default Icon
