import React, { useEffect, useState, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import {
  Container,
  ParallaxContent,
  H2,
  Paragraph,
  ContentContainer,
  Content,
  Image1Container,
  Image2Container,
  KitBoxImg,
  KitCup,
  CupContainer,
  MobilePreviewImg,
} from './styled'
import { ScreenSize } from 'styles/mq'
import LeadEmailCaptureForm from 'components/lead-email-capture-form'
import Urls from 'common/urls'

type QueryResult = {
  boxOpen: FluidImage
  boxClosed: FluidImage
  boxCup: FluidImage
  mobilePreview: FluidImage
}

const query = graphql`
  query {
    boxOpen: file(relativePath: { eq: "kit-box-open-dark-resized.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, webpQuality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    boxClosed: file(relativePath: { eq: "kit-box-closed-resized.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960, webpQuality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    boxCup: file(relativePath: { eq: "kit-box-cup-noshadow.png" }) {
      childImageSharp {
        fluid(maxWidth: 273, webpQuality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    mobilePreview: file(relativePath: { eq: "results-iphone-jake.png" }) {
      childImageSharp {
        fluid(maxWidth: 672, webpQuality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const KitParallax = () => {
  const openBoxRef = useRef<HTMLDivElement>(null)
  const mobilePreviewRef = useRef<HTMLDivElement>(null)
  const [isOpenBoxVisible, setIsOpenBoxVisible] = useState(true)
  const [isMobileVisible, setIsMobileVisible] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)
  const data = useStaticQuery<QueryResult>(query)

  useEffect(() => {
    const boxObserver = new IntersectionObserver(
      function(entries) {
        setIsOpenBoxVisible(!entries[0].isIntersecting)
      },
      { rootMargin: '0% 0% -80% 0%' }
    )

    const mobilePrevObserver = new IntersectionObserver(
      function(entries) {
        setIsMobileVisible(entries[0].isIntersecting)
      },
      { rootMargin: '0% 0% -40% 0%' }
    )

    const backgroundObserver = new IntersectionObserver(
      function(entries) {
        setIsScrolled(!entries[0].isIntersecting)
      },
      { rootMargin: '0% 0% 20% 0%' }
    )

    if (openBoxRef.current && mobilePreviewRef.current) {
      boxObserver.observe(openBoxRef.current)
      mobilePrevObserver.observe(mobilePreviewRef.current)
      backgroundObserver.observe(openBoxRef.current)
    }

    return () => {
      boxObserver.disconnect()
      mobilePrevObserver.disconnect()
      backgroundObserver.disconnect()
    }
  }, [])

  return (
    <Container scrolled={isScrolled}>
      <ParallaxContent>
        <ContentContainer>
          <Content isVisible={isMobileVisible}>
            <H2>Get your comprehensive results.</H2>
            <Paragraph>
              We analyze the most important sperm health factors to give you an
              understanding of your ability to conceive.
            </Paragraph>
            <LeadEmailCaptureForm
              successMessage="Thanks! An email with our sample report is on its way."
              title="See a sample report"
              source="kit-sample-report"
            />
          </Content>
          <Content isFirst isVisible={!isOpenBoxVisible && !isMobileVisible}>
            <H2>Provide a sample and tell us about yourself.</H2>
            <Paragraph>
              We test your sample in our secure &amp; CLIA-certified biolab in
              California and write up a full report based on the results.
            </Paragraph>
          </Content>
        </ContentContainer>

        <Image1Container ref={openBoxRef}>
          <KitBoxImg
            // @ts-expect-error
            fluid={{
              ...data.boxOpen.childImageSharp.fluid,
              sizes: `(min-width: ${ScreenSize.LG}px) 960px, (min-width: ${ScreenSize.MD}px) 80vw, 100vw`,
            }}
          />
          <CupContainer>
            <KitCup
              visible={isOpenBoxVisible}
              // @ts-expect-error
              fluid={data.boxCup.childImageSharp.fluid}
            />
          </CupContainer>
          <KitBoxImg
            // @ts-expect-error
            fluid={{
              ...data.boxClosed.childImageSharp.fluid,
              sizes: `(min-width: ${ScreenSize.LG}px) 960px, (min-width: ${ScreenSize.MD}px) 80vw, 100vw`,
            }}
            visible={!isOpenBoxVisible}
          />
        </Image1Container>

        <Image2Container ref={mobilePreviewRef}>
          {/* @ts-expect-error */}
          <MobilePreviewImg fluid={data.mobilePreview.childImageSharp.fluid} />
        </Image2Container>
      </ParallaxContent>
    </Container>
  )
}

export default KitParallax
