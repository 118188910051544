import styled from 'styled-components'
import { heading } from 'styles/typography'
import mq from 'styles/mq'

export const Section = styled.section`
  margin: 8rem 0;

  ${mq.medium} {
    margin: 13rem 0;
  }
`

export const Title = styled.h1`
  ${heading.xxl};
  text-align: center;
`
