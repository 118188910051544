import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import AddToCartButton from 'components/add-to-cart-button'
import Container from 'components/container'
import IconCheck from 'components/icons/check'
import IconFlask from 'components/icons/flask'
import IconCLIA from 'components/icons/clia'
import IconClock from 'components/icons/clock'
import {
  Hero,
  Img,
  H1,
  Intro,
  Contents,
  Price,
  ImgTablet,
  ImgMobile,
  Features,
  ReviewsAccessPoint,
  ReviewsAccessPointStars,
  ReviewsAccessPointLink,
  MobileHideButtonWrapper,
} from './styled'
import useKitProduct from 'hooks/use-kit-product'
import { formatCurrency } from 'utils/format'
import Urls from 'common/urls'
import theme from 'styles/theme'
import { ScreenSize } from 'styles/mq'
import StarRating from 'components/star-rating'
import { aggregateRating } from 'containers/reviews'

interface TestimonialEdge {
  node: Testimonial
}

type QueryResult = {
  hero: FluidImage
  heroTablet: FluidImage
  heroMobile: FluidImage
  testimonialsResult: any
}

const query = graphql`
  query {
    hero: file(relativePath: { eq: "kit-hero-desktop.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1080, webpQuality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heroTablet: file(relativePath: { eq: "kit-hero-tablet-narrow.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1024, webpQuality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    heroMobile: file(relativePath: { eq: "kit-hero-mobile.png" }) {
      childImageSharp {
        fluid(maxWidth: 375, webpQuality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    testimonialsResult: allTestimonialsJson {
      edges {
        node {
          from_physician
        }
      }
    }
  }
`

const KitHero = () => {
  const data = useStaticQuery<QueryResult>(query)
  const product = useKitProduct()
  const testimonialsEdges = data.testimonialsResult.edges
  const filteredTestimonialsEdges = testimonialsEdges.filter(
    (testimonialEdge: TestimonialEdge) => !testimonialEdge.node.from_physician
  )

  return (
    <Hero>
      <Img
        // @ts-ignore
        fluid={{
          ...data.hero.childImageSharp.fluid,
          sizes: `(min-width: ${ScreenSize.LG}px) 50vw, 100vw`,
        }}
        fadeIn={false}
      />
      <ImgTablet
        // @ts-ignore
        fluid={{
          ...data.heroTablet.childImageSharp.fluid,
        }}
        fadeIn={false}
      />
      <ImgMobile
        // @ts-ignore
        fluid={{
          ...data.heroMobile.childImageSharp.fluid,
        }}
        fadeIn={false}
      />

      <Container>
        <H1>Semen Analysis</H1>
        <Intro>
          <ReviewsAccessPoint>
            <ReviewsAccessPointStars>
              <StarRating
                rating={aggregateRating}
                name="aggregate-rating-kit"
                fillColor={theme.color.accent.secondary}
                starDimension="2rem"
                starSpacing="0.1rem"
              />
            </ReviewsAccessPointStars>
            <ReviewsAccessPointLink>
              {filteredTestimonialsEdges ? (
                <a href={Urls.reviews}>
                  {filteredTestimonialsEdges.length} reviews
                </a>
              ) : null}
            </ReviewsAccessPointLink>
          </ReviewsAccessPoint>
          <p>
            Fast, clinically validated<sup>§</sup> semen analysis without
            visiting a lab.
          </p>
          <Contents isExpanded={true}>
            <li>
              <strong>Convenient. </strong>At-home, mail-in sperm test. Free
              shipping both ways.
            </li>
            <li>
              <strong>Comprehensive. </strong>Easy-to-understand results include
              the sperm metrics a doctor would expect to receive.
            </li>
            <li>
              <strong>Best-In-Class. </strong>Our CLIA-certified lab provides
              the only mail-in semen analysis to be peer-reviewed and clinically
              validated.
            </li>
          </Contents>
          <Price>${formatCurrency(product.price)}</Price>
          <MobileHideButtonWrapper>
            <AddToCartButton product={product} CTA="Add to cart" />
          </MobileHideButtonWrapper>
          <Features>
            <li>
              <IconCheck /> Doctor Approved
            </li>
            <li>
              <IconFlask /> Privacy & Security
            </li>
            <li>
              <IconCLIA /> CLIA Certified
            </li>
            <li>
              <IconClock /> Fast Results
            </li>
          </Features>
        </Intro>
      </Container>
    </Hero>
  )
}

export default KitHero
