import styled from 'styled-components'

import theme from 'styles/theme'
import { heading, paragraph } from 'styles/typography'
import mq from 'styles/mq'

export const Section = styled.section`
  padding: 8rem 0;
  background: ${theme.color.background.gray};

  ${mq.medium} {
    padding: 14rem 0;
  }

  ${mq.large} {
    padding-top: 0;
  }
`

export const Heading = styled.h2`
  ${heading.xxs};
  color: inherit;
  margin: 0 0 2.4rem;
`

export const List = styled.ul`
  display: grid;
  margin: 0;
  padding: 0;
  grid-template-columns: 1fr;
  grid-gap: 2.4rem;

  ${mq.medium} {
    grid-template-columns: 1fr 1fr;
  }

  ${mq.large} {
    grid-template-columns: repeat(3, 1fr);
  }

  li {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 3.2rem;
    background: white;

    ${mq.medium} {
      padding: 4rem;
    }
  }

  p {
    ${paragraph.small};
    margin: 0 0 2rem;
    line-height: 1.4;
  }

  h3 {
    ${heading.xxs};
    margin: 0;
    color: ${theme.color.accent.secondary};
  }

  h4 {
    ${heading.m};
    line-height: 1.2;
    margin: 2rem 0;
  }

  svg {
    display: block;
    margin: auto 0 0 -0.12em;
    font-size: 8.7rem;
    color: ${theme.color.accent.secondary};
  }
`
export const LinkWrapper = styled.div`
  text-align: center;
  padding-top: 4rem;
`
