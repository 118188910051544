import styled, { css } from 'styled-components'
import ImgBase from 'gatsby-image'

import theme from 'styles/theme'
import { heading, paragraph } from 'styles/typography'
import mq, { ScreenSize } from 'styles/mq'
import ContainerBase from 'components/container'

export const Container = styled.div<{ scrolled?: boolean }>`
  position: relative;
  width: 100%;
  overflow: hidden;
  transition: background 0.5s;
  background: ${theme.color.background.light};

  ${mq.large} {
    overflow: visible;

    ${props =>
      props.scrolled &&
      css`
        background-color: ${theme.color.background.gray};
      `}
  }
`

export const ParallaxContent = styled(ContainerBase as any)`
  position: relative;
  display: grid;
  padding: 4rem 3rem;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto 23rem;
  grid-template-areas: 'text1' 'image1' 'text2' 'image2';
  z-index: 0;
  max-width: ${ScreenSize.LG / 10}rem;

  ${mq.medium} {
    padding: 8rem 7rem;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'text1 text1' 'image1 image1' 'text2 image2';
  }

  ${mq.large} {
    grid-template-columns: 40rem 1fr;
    grid-template-areas: 'text image1' 'text image2';
  }
`

export const ContentContainer = styled.div`
  display: contents;

  ${mq.large} {
    display: block;
    grid-area: text;
  }
`

export const Content = styled.div<{ isFirst?: boolean; isVisible?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: opacity 0.3s;

  ${mq.large} {
    max-width: 40rem;
  }

  ${props =>
    props.isFirst
      ? css`
          grid-area: text1;

          ${mq.large} {
            position: fixed;
            top: 0;
            left: calc(50% - 57rem);
            width: 100%;
            height: 100%;
            padding: 5rem;
            max-width: 50rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            pointer-events: none;
          }
        `
      : css`
          grid-area: text2;

          ${mq.large} {
            position: sticky;
            top: 0;
            height: 100vh;
          }
        `}

  ${props =>
    props.isVisible === false &&
    css`
      ${mq.large} {
        opacity: 0;
      }
    `}
`

export const H2 = styled.h2`
  ${heading.xl};
  margin-bottom: 0;
`

export const Paragraph = styled.p`
  ${paragraph.small}
  line-height: 1.5;
`

export const Image1Container = styled.div`
  position: relative;
  grid-area: image1;
  margin-bottom: 5rem;
  z-index: -1;

  ${mq.large} {
    min-height: 100vh;
    margin-bottom: 0;
  }
`

export const Image2Container = styled(Image1Container as any)`
  grid-area: image2;
`

export const CupContainer = styled.div`
  position: absolute !important;
  bottom: 50%;
  left: 49.5%;
  width: 28%;
  height: 50%;
  transform: translate(-50%, 5%);
  overflow: hidden;

  ${mq.medium} {
    width: 20%;
    left: 39.7%;
  }

  ${mq.large} {
    width: auto;
    transform: translate(22%, 4%);
    height: 46rem;
  }
`

export const KitCup = styled(ImgBase)<{ visible?: boolean }>`
  width: 100%;
  transition: 1.8s transform;

  ${props =>
    !props.visible &&
    css`
      transform: translateY(86%) scale(0.82);

      ${mq.medium} {
        transform: translateY(78%) scale(0.75);
      }

      ${mq.large} {
        transform: translateY(28rem) scale(0.68);
      }
    `}

  img {
    display: block;
  }

  ${mq.large} {
    width: 27.3rem;
    height: 32rem;
  }
`

export const KitBoxImg = styled(ImgBase)<{ visible?: boolean }>`
  width: calc(100% + 6rem);
  margin-left: -3rem;
  transition: 0.4s opacity 2s;

  &:first-of-type {
    position: absolute !important;
  }

  ${props =>
    props.visible === false &&
    css`
      opacity: 0;
      transition: 0.4s opacity;
    `}

  img {
    display: block;
    height: auto;
  }

  ${mq.medium} {
    width: 80%;
    margin-left: 0;
  }

  ${mq.large} {
    position: absolute !important;
    top: 50%;
    left: -4%;
    transform: translateY(-50%);
    width: 96rem;
    max-width: calc(50vw + 13.8rem);
    margin: 0;

    img {
      width: 96rem !important;
      object-position: 0 center !important;
    }
  }
`

export const MobilePreviewImg = styled(ImgBase)`
  position: relative !important;
  width: 100%;
  max-width: 34rem;
  margin: 0 auto;
  object-position: 50% 0;

  img {
    object-position: center 33.33% !important;
    transform: translateY(0.5rem);
  }

  ${mq.extraSmall} {
    width: 70%;

    img {
      object-position: center 33.33% !important;
      transform: translateY(6rem);
    }
  }

  ${mq.medium} {
    width: 70%;
    height: auto;
    left: 16%;
    right: 0;
    max-width: 100%;
    margin: 3rem 0 0;

    img {
      transform: translateY(0.5rem);
    }
  }

  ${mq.large} {
    width: 45rem;
    left: 36%;
  }
`
