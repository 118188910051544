import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const BankIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props} viewBox="0 0 48 48">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M38.4692 38.5096v2.7215H40V44H8v-2.7689h1.5308v-2.7215h1.5227V22.7029H9.5308v-2.7684h2.5495l11.3792-8.7323V4.4283c3.3196-.464 5.2055-.589 5.2055-.1853 0 .1558-.18.3708-.3504.5744-.3249.388-.615.7345.4423.5503.2583-.045.577-.1351.929-.2347.8558-.242 1.9085-.5397 2.769-.3804V8.872c-.5566-.1984-1.109-.3145-2.1298.046-2.8602 1.0107-2.4229.0246-2.1387-.6163.1021-.2303.1844-.416.0871-.4486-.2736-.092-1.084.3124-1.9935.7662-.5752.287-1.19.5938-1.7338.8073v1.78l11.3735 8.7279h2.5495v2.7684h-1.5227v15.8067h1.5227zm-10.0601 0h-3.0326V22.7029h3.0326v15.8067zm-5.7851 0h-3.0323V22.7029h3.0323v15.8067zm11.57 0h-3.0323V22.7029h3.0323v15.8067zm-17.3549 0H13.806V22.7029h3.0331v15.8067zM25.0903 16.91L24.5 15l-.5903 1.91H22l1.5451 1.18-.59 1.91L24.5 18.8194 26.0449 20l-.59-1.91L27 16.91h-1.9097z"
      clipRule="evenodd"
    />
  </Svg>
)

export default BankIcon
