import React, { FC } from 'react'
import Svg from 'components/svg'

const Icon: FC = props => (
  <Svg {...props}>
    <path
      d="M12 44.5a32 32 0 1 1 32 32 32.036 32.036 0 0 1-32-32zm2.515 0A29.485 29.485 0 1 0 44 15.016 29.518 29.518 0 0 0 14.515 44.5zm2.609 0A26.876 26.876 0 1 1 44 71.376 26.906 26.906 0 0 1 17.124 44.5zm2.515 0A24.362 24.362 0 1 0 44 20.138 24.388 24.388 0 0 0 19.638 44.5zm2.609 0A21.753 21.753 0 1 1 44 66.253 21.777 21.777 0 0 1 22.247 44.5zm2.515 0A19.238 19.238 0 1 0 44 25.262 19.259 19.259 0 0 0 24.762 44.5zm2.609 0A16.628 16.628 0 1 1 44 61.129 16.648 16.648 0 0 1 27.371 44.5zm2.515 0A14.114 14.114 0 1 0 44 30.387 14.13 14.13 0 0 0 29.886 44.5zm2.609 0A11.5 11.5 0 1 1 44 56.005 11.519 11.519 0 0 1 32.495 44.5zm2.515 0A8.99 8.99 0 1 0 44 35.51a9 9 0 0 0-8.991 8.99z"
      fill="currentColor"
    />
  </Svg>
)

export default Icon
