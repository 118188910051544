import styled from 'styled-components'

import theme from 'styles/theme'
import { heading } from 'styles/typography'
import mq from 'styles/mq'

import BirdAndBee from 'components/icons/bird-and-bee'

export const Section = styled.section`
  background: ${theme.color.background.gray};
  padding: 8rem 0 0;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${mq.desktop} {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const ContentWrapper = styled.div`
  margin-left: 2rem;

  ${mq.desktop} {
    margin-left: 0;
    padding-left: 2rem;
  }
`
export const ImgWrapper = styled.div`
  display: flex;
  width: 100%;

  ${mq.desktop} {
    width: 30rem;
    height: 30rem;
  }
`

export const DesktopBirdAndBee = styled(BirdAndBee)`
  color: ${theme.color.accent.secondary};
  display: none;

  ${mq.desktop} {
    display: inherit;
    width: 100%;
    height: 100%;
  }
`

export const MobileBirdAndBee = styled(BirdAndBee)`
  color: ${theme.color.accent.secondary};
  height: 20rem;
  width: 20rem;
  margin: 0 auto;

  ${mq.desktop} {
    display: none;
  }
`

export const List = styled.ul`
  li {
    margin: 1rem 0;
    list-style: none;

    &:before {
      content: '';
      float: left;
      width: 0.4rem;
      height: 0.4rem;
      margin: 0.6em 0.5em 0 -1em;
      background: ${theme.color.accent.primary};
    }
  }

  &:nth-of-type(2) {
    li:first-of-type {
      margin-top: 0;
    }
  }

  ${mq.small} {
    min-width: 100%;
  }

  ${mq.desktop} {
    min-width: 33rem;

    &:nth-of-type(2) {
      li:first-of-type {
        margin: 1rem 0;
      }
    }
  }

  ${mq.large} {
    min-width: 40rem;
  }
`

export const Heading = styled.h1`
  ${heading.l};
`

export const Subtitle = styled.h4`
  ${mq.large} {
    margin-bottom: 0;
  }
`