import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const ClockIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props}>
    <path d="M39.497 5C19.924 5 4 20.8 4 40.219c0 19.413 15.924 35.213 35.497 35.213C59.073 75.432 75 59.632 75 40.219 75 20.799 59.073 5 39.497 5zm1.87 64.778v-4.44H37.63v4.44C22.644 68.85 10.638 56.94 9.706 42.076h4.473v-3.71H9.703c.932-14.869 12.938-26.776 27.924-27.703V15.1h3.737v-4.443c14.99.928 26.995 12.835 27.93 27.704h-4.476v3.71h4.476c-.932 14.866-12.938 26.776-27.927 27.707z" fill="currentColor"/>
    <path d="M37.63 39.366l-9.97 16.163 3.694 1.77 9.367-15.68c.413-.354.648-.865.648-1.402V20.22h-3.74v19.147z" fill="currentColor"/>
  </Svg>
)

export default ClockIcon
