import React from 'react'

import IconFlask from 'components/icons/flask'
import BankIcon from 'components/icons/bank'
import { PrimaryLink } from 'components/links'
import Urls from 'common/urls'
import {
  Wrapper,
  Inner,
  Block,
  Content,
  BlockTitle,
  BlockText,
  ImageWrapper,
} from 'containers/home/cta/styled'

const KitPrivacy = () => (
  <Wrapper>
    <Inner>
      <Block>
        <ImageWrapper>
          <IconFlask />
        </ImageWrapper>
        <Content>
          <BlockTitle>Tested and protected.</BlockTitle>
          <BlockText>
            Every sample is preserved securely in our state-of-the-art,
            CLIA-certified lab as it awaits analysis.
          </BlockText>
          <PrimaryLink to={Urls.science}>Learn more</PrimaryLink>
        </Content>
      </Block>
      <Block>
        <ImageWrapper>
          <BankIcon />
        </ImageWrapper>
        <Content>
          <BlockTitle>Your data is yours (and yours only).</BlockTitle>
          <BlockText>
            We take your privacy seriously. Your results are delivered via our
            secure platform, and are only shared with you (and with your doctor,
            if you choose).
          </BlockText>
          <PrimaryLink to={Urls.terms}>Learn more</PrimaryLink>
        </Content>
      </Block>
    </Inner>
  </Wrapper>
)

export default KitPrivacy
