import styled from 'styled-components'
import theme from 'styles/theme'
import mq from 'styles/mq'
import { heading, paragraph } from 'styles/typography'

// Note that these elements are being used on the Kit Privacy container as well.
// Should pull into a component the next time we use them.

export const Wrapper = styled.section`
  background: ${theme.color.background.light};
  border-top: 0.1rem solid ${theme.color.border.blue};
`

export const Inner = styled.div`
  max-width: 144rem;
  margin: 0 auto;

  ${mq.medium} {
    display: flex;
  }
`

export const Block = styled.div`
  flex: 1;
  padding: 6.4rem 3rem;

  ${mq.medium} {
    display: flex;
  }

  ${mq.large} {
    padding: 8rem;
  }

  &:not(:last-child) {
    border-bottom: 0.1rem solid ${theme.color.border.blue};

    ${mq.medium} {
      border-bottom: none;
      border-right: 0.1rem solid ${theme.color.border.blue};
    }
  }
`

export const ImageWrapper = styled.div`
  width: 12.8rem;
  flex-shrink: 0;
  margin-bottom: 2.5rem;
  color: ${theme.color.accent.secondary};
  font-size: 9rem;

  .gatsby-image-wrapper {
    width: 11rem;
  }

  ${mq.medium} {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }
`

export const Content = styled.div`
  ${mq.medium} {
    padding-left: 3rem;
  }
`

export const BlockTitle = styled.h5`
  ${heading.m};
  margin-bottom: 1.6rem;
  line-height: 1.3;
`

export const BlockText = styled.p`
  ${paragraph.small};
`
